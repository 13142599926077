import React, {useState, useEffect } from "react"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import { Helmet } from "react-helmet"

const ImageGallery = ({ images, loading, fetchImages }) => {
    // Create gallery here
    return (
      <InfiniteScroll
        dataLength={images.length}
        next={() => fetchImages()}
        hasMore={false}
        loader={
          <p style={{ textAlign: "center", marginTop: "1%" }}>
            Loading 🐕 🐕...
          </p>
        }
      >
        <div className="image-grid">
          {!loading
            ? images.map(image => (
                <div className="image-item" key={image.id}>
                  <a href={image.permalink} target="_blank"><img src={image.media_url} alt={image.caption} /></a>
                </div>
              ))
            : ""}
        </div>
      </InfiniteScroll>
    )
  }

  const InfiniteImages = ({ instagram }) => {
    // Hold state
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)
  
    // Fetch images on component mount
    useEffect(() => {
      fetchImages()
    }, [])
  
    // Fetch Images from functions
    const fetchImages = () => {
        axios(`/api/instagram/${instagram}`).then(res => {
        console.log(res)
        setImages([...images, ...res.data.business_discovery.media.data])
        setLoading(false)
        })
    }
    return (
      <div>
      <ImageGallery images={images} loading={loading} fetchImages={fetchImages} />
      </div>
    )
  }

  export default InfiniteImages