import { React } from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Helmet } from "react-helmet"

import Layout from 'components/Layout';
import Container from 'components/Container';
import SEO from 'components/SEO';
import InfiniteImages from 'components/InfiniteImages';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const cafe = data.airtable.data

  let directionsURL = `https://www.google.com/maps/dir/?api=1`
  directionsURL += ('&destination='+ encodeURIComponent(cafe.title + ', ' + cafe.address));
  directionsURL += (cafe.google_place_id ? '&destination_place_id=' + cafe.google_place_id : '');

  return (
    <Layout pageName="cafe">
      <Helmet>
        <link rel="preload" href={`/api/instagram/${cafe.instagram}`} as="fetch" crossorigin></link>
      </Helmet>
      <SEO title={cafe.title} />
      <Container type="scrollable">
        <Link to={'/'}><span className="toggle-sidebar-button"><FontAwesomeIcon className="hamburger" icon={ faTimes } size='1x'/></span></Link>
        <div className="donut-listing">
          <h1 className="feature-title cafe-title">{cafe.title}</h1>
          <span><p>{cafe.address}</p></span>
  { cafe.flavours ? <p>Flavours: { cafe.flavours} </p> : <p></p> }
  { cafe.availability ? <p>Availability: { cafe.availability} </p> : <p></p> }

          <a href={directionsURL}>Get directions</a><br />
          { cafe.website ? <a href={cafe.website} target="_blank" rel="noreferrer">{cafe.website}</a> :<p></p> }
          <div>
            { cafe.gluten_free ? <abbr title="Gluten-free options" class="letter-circle">GF</abbr> : <span></span>}

          </div>
        </div>
        <InfiniteImages instagram={cafe.instagram}></InfiniteImages>
        <div/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query GetRecord($recordId: String!){
  airtable(recordId: { eq: $recordId}) {
      id
      table
      recordId
      data {
        title
        slug
        address
        instagram
        website
        path
        google_place_id
        gluten_free
        vegan
        flavours
        availability
        photos {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
  }
}`
